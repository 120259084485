import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import DefaultLayout from "/builds/everyone/dcdcweb-saturday/src/components/Layout/index.js";
const layoutProps = {};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = DefaultLayout;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" Layout={this.layout} layoutProps={Object.assign({}, layoutProps, props)} components={components}>

      <MDXTag name="h1" components={components}>{`Why join DCDC?`}</MDXTag>
      <MDXTag name="h3" components={components}>{`Membership Benefits`}</MDXTag>
      <MDXTag name="ul" components={components}>
        <MDXTag name="li" components={components} parentName="ul">{`The right to vote at/attend company meetings and the right to propose new business ideas at these meetings. Essentially having a say in how the enterprise operates.`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`The right to be elected as/elect a company director.`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`Access to a network of like-minded individuals, with various skillsets and backgrounds.`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`Owning both an economic share in the DCDC co-operative and a digital share in our blockchain.`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`The chance to earn dividends on the your economic share and the ability to generate cryptocurrency on our blockchain network via your digital share.`}</MDXTag>
      </MDXTag>
      <MDXTag name="h3" components={components}>{`Requirements`}</MDXTag>
      <MDXTag name="p" components={components}>{`We only require that you are a person (who is older than 16) and that you are you. (Joining DCDC will be subject to an identity verification)`}</MDXTag>
      <MDXTag name="h3" components={components}>{`Fees`}</MDXTag>
      <MDXTag name="p" components={components}>{`We want DCDC membership to be as inclusive as possible. We only charge joining members for any necessary identity verification, the cost of any physical tool to store your unique digital certificate and the price of one share. `}</MDXTag>
      <MDXTag name="p" components={components}>{`-diagram-`}</MDXTag>
      <MDXTag name="h3" components={components}>{`How to apply`}</MDXTag>
      <MDXTag name="p" components={components}>{`Drop us an email for more information.`}</MDXTag>
           </MDXTag>;
  }

}
export const _frontmatter = {};
    